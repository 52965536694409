import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { uniq } from "lodash"
export default function TagsInput(props) {
    const {
        selectedTags = () => {}, 
        inputValue = '', 
        handleChangeInput = () => {}, 
        tagsValue = [], 
        checkAddTags = true, 
        onBlur = () => {}, 
        dataTestIdInput = "", 
        dataTestIdTagsInput = "",
        type = "text"
    } = props
    const [tags, setTags] = useState(tagsValue)
    const inputRef = useRef(null)

    const removeTags = indexToRemove => {
        setTags([...tags.filter((_, index) => index !== indexToRemove)])
        selectedTags([...tags.filter((_, index) => index !== indexToRemove)],'remove')
    }
    const addTags = event => {
        if (event.target.value !== "") {
            if (checkAddTags) {
                let tagValue = (type === 'number') ? Number(event.target.value) : event.target.value
                setTags(uniq([...tags, tagValue]))
                selectedTags(uniq([...tags, tagValue]),'add')
            } else {
                selectedTags(tags,'add')
            }
            event.target.value = ""
        }
    }
    return (
        <div className="tags-input" data-testid = {dataTestIdTagsInput} onClick = {() => {
            inputRef?.current?.focus()
        }}>
            <ul className="tags">
                {tags.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className='tag-title'>{tag}</span>
                        <span className='tag-close-icon'
                            onClick={() => removeTags(index)}
                        >
                            <i className="icon prism-icon-cross-small"></i>
                        </span>
                    </li>
                ))}
                <input
                    ref={inputRef}
                    data-testid = {dataTestIdInput}
                    value={inputValue}
                    type="text"
                    onChange={handleChangeInput}
                    onKeyDown={event => {
                      return  (event.code.includes('Enter') || event.code === 'Space') && addTags(event)
                    }}
                    onBlur = {onBlur}
                />
            </ul>
        </div>
    )
}
TagsInput.propTypes = {
    tagsValue: PropTypes.array,
    selectedTags: PropTypes.func,
    handleChangeInput: PropTypes.func,
    inputValue: PropTypes.string,
    checkAddTags: PropTypes.bool,
    onBlur: PropTypes.func,
    dataTestIdInput: PropTypes.string,
    dataTestIdTagsInput: PropTypes.string,
    type: PropTypes.string
}