import update from 'immutability-helper'
import { Constants } from '../../utils'
import * as ACTION_TYPES from '../actions/actionTypes'

const { ERROR_FETCHING_DATA } = Constants

export const initialState = {
  rolesByUserType: [],
  roles: [],
  isPending: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
}

const RoleReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ACTION_TYPES.GET_ROLES_BY_USER_TYPE.PENDING: {
      return update(state, {
        rolesByUserType:{
          $set: []
        },
        isPending: {
          $set: true
        },
        isSuccess: {
          $set: false
        },
        isError: {
          $set: false
        },
        errorMessage: {
          $set: ''
        }
      })
    }
    case ACTION_TYPES.GET_ROLES_BY_USER_TYPE.SUCCESS: {
      return update(state, {
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        isError: {
          $set: false
        },
        errorMessage: {
          $set: ''
        }
      })
    }
    case ACTION_TYPES.GET_ROLES_BY_USER_TYPE.NAME: {
      return update(state, {
        rolesByUserType: {
          $set: payload
        },
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        isError: {
          $set: false
        },
        errorMessage: {
          $set: ''
        }
      })
    }
    case ACTION_TYPES.GET_ROLES_BY_USER_TYPE.FAILURE: {
      let errorMessage = '';
      if (payload === 500) {
        errorMessage = ERROR_FETCHING_DATA
      }
      return update(state, {
        rolesByUserType:{
          $set: []
        },
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: false
        },
        isError: {
          $set: true
        },
        errorMessage: {
          $set: errorMessage
        }
      })
    }
    default:
      return state
  }
}

export default RoleReducer
