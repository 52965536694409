import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@prism/library'
import { IconSvg } from '@prism/icon'
import { Constants } from '../../utils'

const { COLOR } = Constants

const InformationModal = ({
  isOpen = false,
  dataFields = [],
  data = [],
  onClose
}) => {
  return (
    <Modal isOpen={isOpen}>
      <div className='d-flex justify-content-end'>
        <div onClick={onClose} data-testid="mod-close">
          <IconSvg className="cursor-pointer" glyph="cross-small" height="30" color={COLOR.DARK_BLUE} />
        </div>
      </div>
      <div className="pl-2 pr-2">
        <table data-testid="mod-table-content">
          <thead>
            <tr>
            {dataFields.map((name, index) => 
              <td key={index} data-testid={`td-mod-header-${index}`} className="text-light font-weight-bold">{name}</td>)}
            </tr>
          </thead>
          <tbody>
          {data.map((item, index) => 
            <tr key={index} data-testid={`tr-mod-body-${index}`}>
            {Object.keys(item).map((k) => (
              <td key={k} data-testid={`td-mod-body-${k}-${index}`}
                className={k === 'title' ? 'font-weight-bold' : 'text-left'}>{item[k]}</td>
            ))}
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

InformationModal.propTypes = {
  isOpen: PropTypes.bool,
  dataFields: PropTypes.array,
  data: PropTypes.array,
  onClose: PropTypes.func
}

export default InformationModal
