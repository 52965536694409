import React from 'react'
import { IconSvg } from '@prism/icon'
import { COLOR } from '../utils/constants'

export default function ErrorPermission (props) {
  const { content } = props || {}
  const { message = '', status = 'error' } = content || {}
  return (
    <div className="error-page">
      <div className="media">
        <IconSvg glyph="error-triangle" className="align-self-center mr-4" color={ COLOR.DARK_BLUE } height="85px" />
        <div className="media-body">
          <div className="access-denied-page__status">
            <h2 className="mb-2 mt-2">{status}</h2>
          </div>
          <div className="access-denied-page__message">
            <p className='description' dangerouslySetInnerHTML={{ __html: message }}></p>
          </div>
        </div>
      </div>
    </div>
  )
}
