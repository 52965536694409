import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Constants } from '../../utils'
import { formatProductName, refreshFilterData } from './utils/helper'
import { displayLocation } from '../../utils/actionHelper'
import { map } from 'lodash'
import { Button } from '@prism/library'
import { IconSvg } from '@prism/icon'
import { searchDataUser } from '../../store/actions/searchUser'
import { CheckBoxComponent, SwitchComponent } from '../BasicComponent'
import BlockedIconCell from './BlockedIconCell'
import VickiUserForm from './VickiUserForm'
import UserManagementService from '../../services/userManagementService'
import * as ACTIONS from '../../store/actions/index'
import * as TYPE from '../../store/actions/actionTypes'

const {
  ALL_AUCTIONS,
  COLOR,
  DEFAULT_ALL_INVENTORY,
  DEFAULT_MY_INVENTORY,
  DEFAULT_PRODUCT_VALUE,
  DEFAULT_PRODUCT,
  LABEL_CLIENT,
  ERROR_SAVING_DATA,
  ERROR_USER_ID_INVALID
} = Constants


const VickiUserDetail = ({
  data,
  isEditable = false,
  onToggleActivation,
  onToggleEditMode
}) => {
  const dispatch = useDispatch()
  const { fullName, userId, applications = [], userType } = data
  
  let editRow = -1
  if (!applications.length) {
    editRow = 0
    applications.push({
      name: '',
      allInventoryAccess: true,
      authorizedToAccess: [],
      isActive: true
    })
  }
  const [editRowIndex, setEditRowIndex] = useState(editRow)
  const [indexInvalid, setIndexInvalid] = useState(-1)


  useEffect(() => {
    const isEditMode = editRowIndex > -1
    onToggleEditMode(isEditMode)
  }, [editRowIndex])

  const renderActions = (isActive, index, isValidData = true) => (
    isValidData ? (
        <div>
        <Button id={`btn-edit-${index}`} data-testid={`btn-edit-${index}`} 
          onClick={() => setEditRowIndex(index)}
          disabled={!isActive}>
          <IconSvg glyph="edit" color={isActive ? COLOR.BLUE : COLOR.GRAY} height="42" />
        </Button>
        <SwitchComponent id={`cbx-activate-${index}`} dataTestId={`cbx-activate-${index}`}
          className="align-center" onChange={() => onToggleActivation(applications[index])}
          checked={isActive} value={isActive} />
      </div>
    ) : (
        <div className={`case-invalid-data ${indexInvalid === index ? 'show' : ''}`} 
          data-testid={`invalid-data-icon-${index}`}
          onMouseOver={() => setIndexInvalid(index)} 
          onMouseOut={() => setIndexInvalid(-1)}>
          <IconSvg glyph="error-triangle"
            height="21" />
          <p data-testid={`invalid-data-text-${index}`}>Invalid data</p>
      </div>
      )
  )

  const renderItemList = (arr) => {
    return map(arr, (word, index) => <div key={index}>{word}</div>)
  }

  const renderDetailAccess = (item, index, subIndex) => {
    return (
      <>
        <td data-testid={`td-auction-${index}-${subIndex}`}>
          {displayLocation(item.locationName, item.locationCode)}
        </td>
        <td data-testid={`td-inventory-access-${index}-${subIndex}`}>
          {item.allInventoryAccess ? DEFAULT_ALL_INVENTORY : DEFAULT_MY_INVENTORY}
        </td>
        {item.groupCodes && item.groupCodes.length ?
          (<td data-testid={`td-group-code-${index}-${subIndex}`}>{renderItemList(item.groupCodes)}</td>) :
          <BlockedIconCell dataTestId={`td-group-code-${index}-${subIndex}`} />}
        {item.sellerNumbers && item.sellerNumbers.length ?
          (<td data-testid={`td-seller-number-${index}-${subIndex}`}>{renderItemList(item.sellerNumbers)}</td>) :
          <BlockedIconCell dataTestId={`td-seller-number-${index}-${subIndex}`} />}
      </>
    )
  }

  const handleSubmitForm = async (value) => {
    try {
      const params = {
        name: fullName,
        userId: userId,
        emailId: '',
        userType: userType,
        product: DEFAULT_PRODUCT_VALUE,
        isActive: value.isActive,
        role: value.role
      }
      dispatch(ACTIONS.actionsPending(TYPE.ACTION_PENDING))
      const response = await UserManagementService.updateDataUser(params)
      if (response.status === 204) {
        dispatch(searchDataUser({ 
          userType: LABEL_CLIENT, 
          userValue: encodeURIComponent(userId)
        }))
        const activatedParams = JSON.parse(localStorage.getItem("activatedParams"))
        const deactivatedParams = JSON.parse(localStorage.getItem("deactivatedParams"))
        dispatch(refreshFilterData(activatedParams, true))
        dispatch(refreshFilterData(deactivatedParams, false))
        setEditRowIndex(-1)
      }
    }
    catch (error) {
      const { status } = error.response
      switch (status) {
        case 500:
          dispatch(ACTIONS.actionsFailure(TYPE.GET_ERR_EXTERNAL_SERVICE.FAILURE, ERROR_SAVING_DATA))
          break
        case 404:
          dispatch(ACTIONS.actionsFailure(TYPE.GET_ERR_EXTERNAL_SERVICE.FAILURE, ERROR_USER_ID_INVALID))
          break
      }
    }
  }

  return (
    <>
      {map(applications, (application, index) => {
        const { authorizedToAccess } = application
        if (editRowIndex !== index) {
          return (authorizedToAccess && authorizedToAccess.length) ? (
            <Fragment key={application.name}>
              {map(authorizedToAccess, (ataItem, subIndex) => {
                let rowSpanNum = authorizedToAccess?.length
                if (!rowSpanNum) rowSpanNum = 1
                return subIndex === 0 ? (
                  <tr key={subIndex} data-testid={`tr-row-${index}-${subIndex}`}>
                    <td data-testid={`td-name-${index}`} rowSpan={rowSpanNum}>
                      {fullName}
                    </td>
                    <td data-testid={`td-product-${index}`} rowSpan={rowSpanNum}>
                      {formatProductName(application.name)}
                    </td>
                    <td data-testid={`td-global-access-${index}`} rowSpan={rowSpanNum}>
                      <CheckBoxComponent
                        checked={application.allInventoryAccess}
                        disabled={true}
                        dataTestId={`cbx-global-access-${index}`}
                        value={application.allInventoryAccess} />
                    </td>
                    {renderDetailAccess(ataItem, index, subIndex)}
                    <td data-testid={`td-role-${index}`} rowSpan={rowSpanNum}>
                      {application?.role?.displayName}
                    </td>
                    {isEditable ? <td data-testid={`td-action-${index}`} rowSpan={rowSpanNum}>
                      {renderActions(application.isActive, index, application.isValid)}
                    </td> : <></>}
                  </tr>
                ) : (
                  <tr key={subIndex} data-testid={`tr-row-${index}-${subIndex}`}>
                    {renderDetailAccess(ataItem, index, subIndex)}
                  </tr>
                )
              })}
            </Fragment>
          ) : (
            <Fragment key={application.name}>
              <tr data-testid={`tr-row-${index}`}>
                <td data-testid={`td-name-${index}`}>
                  {fullName}
                </td>
                <td data-testid={`td-product-${index}`}>
                  {formatProductName(application.name)}
                </td>
                {application.allInventoryAccess !== undefined ? 
                <td data-testid={`td-global-access-${index}`}>
                  <CheckBoxComponent
                    checked={application.allInventoryAccess}
                    disabled={true}
                    dataTestId={`cbx-global-access-${index}`}
                    value={application.allInventoryAccess} />
                </td> : <BlockedIconCell dataTestId={`td-global-access-${index}`} />}
                {application.allInventoryAccess ?
                  <td data-testid={`td-auction-${index}`}>{ALL_AUCTIONS}</td> :
                  <BlockedIconCell dataTestId={`td-auction-${index}`} />
                }
                <BlockedIconCell dataTestId={`td-inventory-access-${index}`} />
                <BlockedIconCell dataTestId={`td-group-code-${index}`} />
                <BlockedIconCell dataTestId={`td-seller-number-${index}`} />
                <BlockedIconCell dataTestId={`td-company-${index}`} />
                <td data-testid={`td-role-${index}`}>
                  {application?.role?.displayName}
                </td>
                {isEditable ? <td data-testid={`td-action-${index}`}>
                  {renderActions(application.isActive, index, application.isValid)}
                </td> : <></>}
              </tr>
            </Fragment>
          )
        }
        else {
          return isEditable ? (
            <Fragment key={application.name}>
              <VickiUserForm fullName={fullName} data={application}
                onConfirm={handleSubmitForm}
                onCancel={() => setEditRowIndex(-1)} />
            </Fragment>
          ) : (
            <tr key={index}>
              <td data-testid={`td-name-${index}`}>{fullName}</td>
              <td data-testid={`td-product-${index}`}>{formatProductName(DEFAULT_PRODUCT)}</td>
              <BlockedIconCell />
            </tr>
          )
        }
      })}
    </>
  )
}

VickiUserDetail.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  onToggleActivation: PropTypes.func,
  onToggleEditMode: PropTypes.func
}

export default VickiUserDetail
