import userManagementService from '../../../services/userManagementService'
import * as ACTIONS from '../index'
import * as TYPE from '../actionTypes'
import {Constants,ActionHelper} from '../../../utils'
import { LIST_OPTIONS_OF_SEARCH_USER } from "../../../utils/constants"

const { ACTION_WITH_BTN_SEARCH } = Constants

export const searchDataUser = (values, type = ACTION_WITH_BTN_SEARCH[0]) => async (dispatch) => {
  dispatch(ACTIONS.actionsPending(TYPE.ACTION_LOADER.PENDING))
  let messageErr = ''
  let typeSearchSuccess = TYPE.SEARCH_DATA_USER.SUCCESS
  let typeSearchFail = TYPE.SEARCH_DATA_USER.FAILURE
  let params = {}
  if (values.userType === LIST_OPTIONS_OF_SEARCH_USER[0]) {
    params = {
      userId: values.userValue.toUpperCase(),
      loginType: Constants.DEFAULT_TYPE_VICKI
    }
    messageErr = Constants.ERROR_USER_ID_INVALID
  } else {
    params = {
      emailId: values.userValue,
      loginType: Constants.DEFAULT_TYPE_OKTA
    }
    messageErr = Constants.ERROR_EMAIL_ID_INVALID
    if (values.userValue 
      && !values.userValue?.toLowerCase().endsWith(encodeURIComponent("@coxautoinc.com"))
      && !values.userValue?.toLowerCase().endsWith(encodeURIComponent("@coxautoprep.com"))) {
      const messageError = ActionHelper.getErrorMessage({ message: messageErr, statusCode: 400, code: 400 })
      dispatch(ACTIONS.actionsFailure(typeSearchFail, messageError))
      return
    }
  }
  try {
    let { data } = await userManagementService.searchDataUser(params)
    if (Array.isArray(data.items) && data.count) {
      dispatch(ACTIONS.actionsSuccess(typeSearchSuccess, {...data.items[0], isEditUser: type === ACTION_WITH_BTN_SEARCH[0] ? false : true}))
    } else {
      const messageError = ActionHelper.getErrorMessage({ message: messageErr, statusCode: 200, code: 200 })
      dispatch(ACTIONS.actionsFailure(typeSearchFail, messageError))
    }
  } catch (error) {
    const { status } = error.response || {}
    const message = ActionHelper.parseErrors(error)
    if (status === 400) {
      const messageError = ActionHelper.getErrorMessage({ message: messageErr, statusCode: 400, code: 400 })
      dispatch(ACTIONS.actionsFailure(typeSearchFail, messageError))
    } else if (status === 500) {
      const messageError = ActionHelper.getErrorMessage({ message: 'Service Unavailable', statusCode: 500, code: 500 })
      dispatch(ACTIONS.actionsFailure(typeSearchFail, messageError))
    } else {
      dispatch(ACTIONS.actionsFailure(typeSearchFail, message))
    }
  }
}

export const dropdownSearchUser = () => async (dispatch) => {
  dispatch(ACTIONS.actionsDropDown(TYPE.DROPDOWN_DATA_USER.SUCCESS))
}
