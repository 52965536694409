import React from 'react'
import { useSelector } from 'react-redux'
import { Message } from '@prism/library'
import SearchUser from '../components/SearchUser'
import UserDetail from '../components/UserDetail/UserDetail'
import LoadingScreen from '../components/LoadingScreen'
import FilterTable from '../components/FilterTable'
import { isEmpty } from 'lodash'
import selectors from '../store/selectors'

export default function Home() {
  const usersSelect = useSelector(selectors.usersSelector.selectUsers)
  const { dataUser, isPending, isError, errorMessage, isTableError } = usersSelect
  const userInfoStr = localStorage.getItem('userInfo')
  const userDetail = userInfoStr && JSON.parse(userInfoStr)
  const isEditable = userDetail?.isAdmin === true
  const isEditUser = dataUser?.isEditUser || false
  const isVEManagerRole = !!(userDetail?.authorities?.map(element => element.authority).includes('SMT_VE_ADMIN'))

  return (
    <div className="container_fluid">
      <LoadingScreen animating={isPending} />
      <div className="padding_top_1"></div>
      <Message isOpen={isTableError ? (isError && errorMessage !== '') : !isTableError} color="danger">
        {errorMessage}
      </Message>
      <SearchUser dataUser={dataUser} isEditUser={isEditUser} isVEManagerRole={isVEManagerRole} />
      {/* {(isTableError ? (!isEmpty(dataUser) && !isError && errorMessage == '') : !isTableError) && (<UserTable data={dataUser} enableBtn = {true} isEditable={isEditable} />)} */}
      {(isTableError ? (!isEmpty(dataUser) && !isError && errorMessage == '') : !isTableError) && (<UserDetail data={dataUser} isEditable={isEditable} isVEManagerRole={isVEManagerRole} isAdminRole={userDetail?.isAdmin}/>)}
      { isVEManagerRole ? <></> : <FilterTable />}
    </div>
  )
}

