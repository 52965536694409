import { map } from 'lodash'
import { GET_AUCTIONS } from './actionTypes'
import userManagementService from '../../services/userManagementService'

export const getAuctions = async (dispatch) => {
  try {
    dispatch({
      type: GET_AUCTIONS.PENDING
    })
    const { data } = await userManagementService.getAuctionOptions()
    const { items } = data
    const auctions = map(items, item => ({ 'value': item.locationCode, 'label': item.locationName }))
    dispatch({
      type: GET_AUCTIONS.SUCCESS
    })
    dispatch({
      type: GET_AUCTIONS.NAME,
      payload: auctions
    })
  }
  catch (error) {
    const { status } = error.response
    if (status === 500) {
      dispatch({
        type: GET_AUCTIONS.FAILURE,
        payload: status
      })
    }
  }
}
