import PropTypes from 'prop-types'

export const actionsPending = (type) => ({
  type
})
actionsPending.propTypes = {
  type: PropTypes.string.isRequired
}

export const actionsSuccess = (type, payload) => ({
  type,
  payload
})
actionsSuccess.propTypes = {
  type: PropTypes.string.isRequired,
  payload: PropTypes.any.isRequired
}

export const actionsFailure = (type, payload) => ({
  type,
  payload
})

actionsFailure.propTypes = {
  type: PropTypes.string.isRequired,
  payload: PropTypes.any.isRequired
}

export const actionsDropDown = (type) => ({
  type
})

actionsDropDown.propTypes = {
  type: PropTypes.string.isRequired
}

