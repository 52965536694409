import React, { useState } from 'react'
import { Button } from '@prism/library'
import PropTypes from 'prop-types'
import { IconSvg } from '@prism/icon'

const SortButton = ({
  color,
  disableColor,
  idName,
  isAscending,
  onClick
}) => {
  const [isHover, setHover] = useState(false)
  const isSorting = isAscending !== undefined
  const displayColor = isHover || isSorting ? color : disableColor
  
  return (
    <Button className="btn-sort d-flex flex-column p-0" onClick={onClick}
    onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {isSorting && <IconSvg id={isAscending ? `${idName}-SortArrowUp` : `${idName}-SortArrowDown`} 
        glyph={isAscending ? "arrow-up" : "arrow-down"} color={displayColor} />}
      {!isSorting && <IconSvg id={`${idName}-DefaultSortArrow`} glyph="arrows-up-down" color={displayColor} />}
    </Button>
  )
}

SortButton.propTypes = {
  color: PropTypes.string,
  disableColor: PropTypes.string,
  idName: PropTypes.string,
  isAscending: PropTypes.bool,
  onClick: PropTypes.func
}

export default SortButton
