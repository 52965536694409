import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '@prism/styles/prism.comps.min.css'

export default function Dropdown(props) {
  const { 
    list, 
    onChange, 
    defaultValue, 
    isObjectList, 
    optionLabel, 
    optionValue, 
    isChecked = false, 
    isDisabled = false,
    ...remainingProps 
  } = props
  const [listOpen, setListOpen] = useState(false)
  const [isScrollClick, setIsScrollClick] = useState(false)
  const configTitle = () => {
    if (!isObjectList) {
      return defaultValue || list[0]
    }

    const selectedItem = list.find(item => item[optionValue] === defaultValue)
    return selectedItem ? selectedItem[optionLabel] : list[0][optionLabel]
  }  
  
  const [title, setTitle] = useState(null)

  useEffect(() => {
    setTitle(configTitle())
  }, [list, isObjectList, defaultValue])

  const toggleList = (e) => {
    e.preventDefault()
    setListOpen(pre => !pre)
    setIsScrollClick(false)
  }

  const handleMouseDown = (e) => {
    e.preventDefault()
    setIsScrollClick(true)
  }
  const handleClick = (selectedItem) => {
    const selectedValue = isObjectList ? selectedItem[optionValue] : selectedItem
    const selectedTitle = isObjectList ? selectedItem[optionLabel] : selectedItem
    onChange(selectedValue)
    setTitle(selectedTitle)
    setListOpen(false)
    setIsScrollClick(false)
  }
  const hidden = (e) => {
    e.preventDefault()
    setIsScrollClick(false)
    setListOpen(false)
  }

  return (
    <div className="dd-wrapper" data-testid="dropdown" {...remainingProps} onBlur={(e) => hidden(e)}>
      <button className="dd-header" id="ddRoleList" data-testid="button-dropdown" onClick={(e) => toggleList(e)} disabled={isDisabled}>
        <div className="dd-header-title">{title}</div>
          <i className="icon prism-icon-triangle-down pr-2" />
      </button>
      {(listOpen || isScrollClick) && <ul className="dd-list" onMouseDown={(e) => handleMouseDown(e)}>
        {list.map((item, index) => (
          <li data-testid={`li${index + 1}`} onClick={() => handleClick(item)} className="dd-list-item" id="item-dropdown" key={index}>
            {((!isObjectList && title === item) || (isObjectList && title === item[optionLabel])
                ? <i className={`pr-2 custom-icon ${isChecked && `icon prism-icon-checkmark-circle`}`} />
                : <i className="icon prism-icon pr-4 custom-icon" />)}
            <p className={`${isChecked ? "item-content-search" : "item-content"}`}>{isObjectList ? item[optionLabel] : item}</p>
          </li>
        ))}
      </ul>}
    </div>
  )
}
Dropdown.defaultProps = {
  list: ['Default'],
  onChange: PropTypes.func,
  defaultValue: null,
  isObjectList: false,
  optionLabel: '',
  optionValue: ''
}
Dropdown.propTypes = {
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  isObjectList: PropTypes.bool,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool
}
