import React from 'react'
import Select from 'react-select'
import { Constants } from '../../utils'
import { capitalize } from 'lodash'
import PropTypes from 'prop-types'

export default function SelectFilter(props) {
  const {
    filterBox,
    data = {options: [], errorMessage: ''}, 
    onChange = () => {},
    onFocus = () => {},
    value
  } = props

  const handleShowMessageNoOption = (typeErr) => {
    if(filterBox === capitalize(Constants.FILTER_TYPE.PRODUCT)){
      return Constants.NO_OPTIONS
    }
    return typeErr ? <div className="text-err">{typeErr}</div> : Constants.NO_ITEMS_FOUND
  }

  const findValidCandidate = (candidate, input) => {
    if (input) {
      return candidate.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
    }
    return true
  }

  return <div className="w-15 mr-2">
    <Select
      id={'select-'+filterBox.toLowerCase()}
      styles={{
        option: (provided) => ({
          ...provided,
          cursor: "pointer"
        })
      }}
      isSearchable={true}
      isClearable={true}
      placeholder={filterBox}
      classNamePrefix={'select-'+filterBox.toLowerCase()}
      options={data.options}
      noOptionsMessage={() => 
        handleShowMessageNoOption(data.errorMessage)
      }
      onChange={onChange}
      onFocus={onFocus}
      filterOption={findValidCandidate}
      value={value}
    />
  </div>
}

SelectFilter.propTypes = {
  filterBox: PropTypes.string,
  data: PropTypes.object,
  value: PropTypes.array,
  noOptionsMessage: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func
}