import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AUTH_URL, APPLICATION_ID as applicationID } from '../utils/api'
import UserManagementService from '../services/userManagementService'
import LoadingScreen from './LoadingScreen'
import ErrorPage from '../pages/ErrorPage'
import AccessDeniedPage from '../pages/AccessDeniedPage'
import { ERROR_STATUS_MESSAGE } from '../utils/constants'

export default function Authentication (props) {
  const { children } = props || {}
  const [isLoading, setIsLoading] = useState(true)
  const [userDetail, setUserDetail] = useState({
    userInfo: {},
    fetchErrorCode: null
  })
  const [headerLoaded, setHeaderLoaded] = useState(false)
  const [showedError, setShowedError] = useState(false)
  const [errorContent, setErrorMessage] = useState({ status: '', message: '' })
  const [isAccessDenied, setIsAccessDenied] = useState(false)

  useEffect(() => {
    const isChange = userDetail.userInfo.username || userDetail.fetchErrorCode !== null
    if (isChange && !headerLoaded) {
      headerLoader(() => {
        if (!userDetail.userInfo.username) {
          showErrorPage()
        }
        setIsLoading(false)
        const btnSignOut = document.getElementById('ewb-logout')
        if (btnSignOut) {
          btnSignOut.addEventListener('click', (event) => {
            event.preventDefault()
            const conditionHref = btnSignOut.getAttribute('href')
            handleLogout(conditionHref)
          })
        }
      })
      setHeaderLoaded(true)
    } else {
      handleCheckLogin()
    }
  }, [userDetail])

  const showErrorPage = () => {
    switch (userDetail.fetchErrorCode) {
      case 401: {
        let count = JSON.parse(window.localStorage.getItem('retryCount')) || 0
        if (count < 3) {
          count++
          window.localStorage.setItem('retryCount', JSON.stringify(count))
          document.location = AUTH_URL
        } else {
          setShowedError(true)
          setErrorMessage({ status: ERROR_STATUS_MESSAGE.DEFAULT.status, message: ERROR_STATUS_MESSAGE.DEFAULT.message, hasReloadBtn: true })
          window.localStorage.setItem('retryCount', '0')
        }
        break
      }
      case 403:
        // redirect to 403 page
        setIsAccessDenied(true)
        setErrorMessage({ status: ERROR_STATUS_MESSAGE.ACCESS_DENIED.status, message: ERROR_STATUS_MESSAGE.ACCESS_DENIED.message })
        break
      default:
        setShowedError(true)
        setErrorMessage({ status: ERROR_STATUS_MESSAGE.DEFAULT.status, message: ERROR_STATUS_MESSAGE.DEFAULT.message, hasReloadBtn: true })
      // redirect error page
    }
  }

  const handleLogout = async (href) => {
    try {
      setIsLoading(true)
      await UserManagementService.logout()
      window.location.href = href
    } catch (error) {
      setShowedError(true)
      setErrorMessage({ status: ERROR_STATUS_MESSAGE.DEFAULT.status, message: ERROR_STATUS_MESSAGE.DEFAULT.message, hasReloadBtn: true })
    }
  }

  const handleCheckLogin = async () => {
    try {
      const getUserDetail = await UserManagementService.getUserDetail()
      localStorage.setItem('userInfo', JSON.stringify(getUserDetail.data))
      setUserDetail({ ...userDetail, userInfo: getUserDetail.data })
      localStorage.setItem('retryCount', '0')
    } catch (error) {
      localStorage.setItem('userInfo', '')
      const { status } = error.response || {}
      setUserDetail({ ...userDetail, fetchErrorCode: status })
    }
  }
  const headerLoader = (loadedCallBack) => {
    const {
      username = '',
      emailAddress = '',
      homeAuction = '',
      firstName = '',
      lastName = '',
      workbenchAppList = [],
      additionalLocations = []
    } = userDetail.userInfo || {}
    const headerDivName = ''
    const allowedApps = workbenchAppList.join(',')
    const additionalAuctions = additionalLocations.join(',')
    const footerDivName = 'ewbFooter'
    const betaTag = true
    const showGear = false
    const pageName = applicationID
    const pageType = 'landingPage'
    const releaseNotesUrl = ''
    const trainingDocsUrl = 'https://cox.policytech.com/'
    // eslint-disable-next-line no-undef
    const context = EmployeeWorkbench.buildContext()
      .addRequiredValues(headerDivName, firstName, lastName,
        emailAddress, allowedApps, applicationID,
        homeAuction, additionalAuctions, footerDivName)
      .showBetaWithApplicationName(betaTag)
      .hideLogout(showedError)
      .addHeaderLoadedCallback(function () {
        loadedCallBack && loadedCallBack(context.workbenchHomePageURL)
      })
      .addHeaderGearIconCallback(showGear, function () {
      })
      .addAnalyticsAttributes(pageName, pageType, username)
      .addHeaderHelpIcon(true, trainingDocsUrl, releaseNotesUrl, true).getContext()

    // eslint-disable-next-line no-undef
    EmployeeWorkbench.injectHeader(context)
  }
  const renderContent = () => {
    if (isLoading) return <LoadingScreen animating={isLoading} />
    if (showedError) {
      return <ErrorPage content={errorContent}/>
    }
    else if (isAccessDenied) {
      return <AccessDeniedPage content={errorContent}/>
    }
    else {
      return userDetail.userInfo.username && <>{children}</>
    }
  }
  return (
    <>
      {renderContent()}
    </>
  )
}

Authentication.propTypes = {
  children: PropTypes.any
}