import React from 'react'
import PropTypes from 'prop-types'
import { HeaderTable, BodyTable } from './BasicTabPaneTableComponent'

export default function TabPaneTableComponent(props) {
  const { dataTestId, data, onSort, isAscending } = props

  const handleSort = (isAsc) => {
    onSort(isAsc)
  }

  return (
    <table data-testid={`${dataTestId}-table-filter`}>
      <HeaderTable dataTestId={dataTestId} onSort={handleSort}
        isAscending={isAscending} />
      <BodyTable dataTestId={dataTestId} data={data} />
    </table>
  )
}

TabPaneTableComponent.propTypes = {
  dataTestId: PropTypes.string,
  data: PropTypes.array,
  onSort: PropTypes.func,
  isAscending: PropTypes.bool
}
