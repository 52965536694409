import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from '@prism/library'
import { IconSvg } from '@prism/icon'
import './UserDetail.scss'
import { Constants } from '../../utils'
import userManagementService from '../../services/userManagementService'
import { searchDataUser } from '../../store/actions/searchUser'
import { getAuctions } from '../../store/actions/auctionAction'
import { refreshFilterData } from './utils/helper'


import VickiUserDetail from './VickiUserDetail'
import OktaUserDetail from './OktaUserDetail'
import ConfirmationModal from './ConfirmationModal'
import InformationModal from './InformationModal'

const {
  DEFAULT_TYPE_VICKI,
  CONFIRM_MESSAGE,
  LABEL_CLIENT,
  LABEL_TEAM_MEMBER,
  ERROR_SOMETHING_WENT_WRONG,
  NOT_FOUND,
  COLOR,
  DEFAULT_INFORMATION_ROLE,
  SERVICE_NOW_URL,
  PRODUCT_OPTIONS,
  IS_NEW_SCHEMA
} = Constants

const UserDetail = ({
  data,
  isEditable = false,
  isVEManagerRole = false,
  isAdminRole = false
}) => {
  const { fullName, emailId, userId, userType, applications = [] } = data
  const confirmData = {
    isOpen: false,
    isError: false,
    title: <span></span>,
    content: <span></span>,
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel'
  }
  const roleInfoData = {
    isOpen: false,
    dataFields: ['Role', 'Description'],
    data: DEFAULT_INFORMATION_ROLE
  }
  const dispatch = useDispatch()
  const auctionOptions = useSelector(state => state.auctions.auctions)

  const [userDetail, setUserDetail] = useState(data)
  const [confirmModalData, setConfirmModalData] = useState(confirmData)
  const [roleInfoModalData, setRoleInfoModalData] = useState(roleInfoData)
  const [selectedApplication, setSelectedApplication] = useState({})
  const [isVickiEditMode, setIsVickiEditMode] = useState(false)
  const [isOktaEditMode, setIsOktaEditMode] = useState(false)

  const isVickiUser = userType === DEFAULT_TYPE_VICKI

  useEffect(() => {
    if (!(auctionOptions && auctionOptions.length))
      getAuctions(dispatch)
  }, [])

  const handleToggleActivation = (value) => {
    const modalTitle = <span>Warning</span>
    
    const modalContent = value.isActive ? 
      <span>{CONFIRM_MESSAGE.DEACTIVATED} <b>{fullName}</b>?</span> : 
      <span>{CONFIRM_MESSAGE.ACTIVATED} <b>{fullName}</b>?</span>

    setConfirmModalData((prev) => ({ 
      ...prev,
      isOpen: true,
      isError: false,
      title: modalTitle,
      content: modalContent,
      confirmLabel: value.isActive ? 'Deactivate' : 'Activate'
    }))
    setSelectedApplication(value)
  }

  const handleAddNewProduct = () => {
    applications.push({
      name: '',
      allInventoryAccess: false,
      authorizedToAccess: [],
      isActive: true
    })
    setUserDetail((prev) => ({ ...prev, applications: applications }))
  }

  const handleSubmitConfirmModal = async () => {
    try {
      const params = {
        userType: userType,
        isActive: !selectedApplication.isActive,
        product: selectedApplication.name
      }
      if (isVickiUser) {
        params['userId'] = encodeURIComponent(userId)
      } else {
        let emailParts = emailId.split('@')
        params['emailId'] = `${encodeURIComponent(emailParts[0])}@${emailParts[1]}`
      }
      const response = await userManagementService.activateDataUser(params)
      if (response.status === 204) {
        const userTypeSearch = isVickiUser ? LABEL_CLIENT : LABEL_TEAM_MEMBER
        setConfirmModalData((prev) => ({ 
          ...prev,
          isOpen: false
        }))
        dispatch(searchDataUser({ 
          userType: userTypeSearch, 
          userValue: encodeURIComponent(isVickiUser ? userId : emailId) 
        }))
        const activatedParams = JSON.parse(localStorage.getItem("activatedParams"))
        const deactivatedParams = JSON.parse(localStorage.getItem("deactivatedParams"))
        dispatch(refreshFilterData(activatedParams, true))
        dispatch(refreshFilterData(deactivatedParams, false))
      }
    }
    catch (error) {
      const { status } = error.response
      if (status === 500 || status === 400) {
        setConfirmModalData((prev) => ({ 
          ...prev,
          isOpen: true,
          isError: true,
          title: <span className="text-danger">Error</span>,
          content: <span>{ERROR_SOMETHING_WENT_WRONG}</span>,
          confirmLabel: 'OK'
        }))
      }
      if (status === 404) {
        setConfirmModalData((prev) => ({ 
          ...prev,
          isOpen: true,
          isError: true,
          title: <span className="text-danger">Error</span>,
          content: <span>{isVickiUser ? userId : emailId} {NOT_FOUND}</span>,
          confirmLabel: 'OK'
        }))
      }
    }
  }

  const handleCancelConfirmModal = () => {
    setConfirmModalData((prev) => ({ 
      ...prev,
      isOpen: false,
      isError: false
    }))
  }

  const handleCloseRoleInfoModal = () => {
    setRoleInfoModalData((prev) => ({
      ...prev,
      isOpen: false
    }))
  }
  const isHaveInspectAndApprove = (applications) => {
    const productList = applications?.map(item => item.name)
    return productList.includes('smart_inspect') && productList.includes('smart_approve')
  }

  const isAddNewProductDisabled = 
    (PRODUCT_OPTIONS.length <= applications.length && isHaveInspectAndApprove(applications)) || 
    !applications.length || 
    isOktaEditMode

  return (
    <div>
      <InformationModal
        {...roleInfoModalData}
        onClose={handleCloseRoleInfoModal} />
      <ConfirmationModal
        {...confirmModalData}
        onConfirm={!confirmModalData.isError ? handleSubmitConfirmModal : handleCancelConfirmModal}
        onCancel={handleCancelConfirmModal}
      />
      <div className={`search-user__table ${isVickiEditMode ? 'no-full-width' : ''}`}>
        {!isVEManagerRole && !isVickiUser && isEditable ? <div className="mb-2">
          <Button
            id="btn-add-new-product" 
            className="text-uppercase btn btn-main"
            onClick={handleAddNewProduct}
            disabled={isAddNewProductDisabled || !IS_NEW_SCHEMA} >
            <IconSvg glyph="plus" height="16px" color={COLOR.DARK} className="mr-2 button-icon" />
            Add new Product
          </Button>
        </div> : <></>}
        <table data-testid="table" className="bg-white">
          <thead data-testid="title">
            <tr data-testid="tr-head-row-0">
              <th data-testid="th-name" className="col-name" rowSpan="2">Name</th>
              <th data-testid="th-product" className="col-product" rowSpan="2">Product</th>
              {!isVickiEditMode && <>
                <th data-testid="th-global-access" rowSpan="2">Global Access</th>
                <th data-testid="th-detail-access" colSpan="4">Detail Access</th>
              </>}
              <th data-testid="th-company" rowSpan="2">Company</th>
              <th data-testid="th-role" className="position-relative col-role" rowSpan="2">
                Role
                {(isVickiEditMode || isOktaEditMode) && <div className="icon-role mr-1" onClick={() => setRoleInfoModalData({
                  ...roleInfoModalData, isOpen: true
                })}>
                  <IconSvg className="pointer" glyph="info-o" color={COLOR.WHITE} height="20" />
                </div>}
              </th>
              {isEditable ? <th data-testid="th-action" rowSpan="2">Action</th> : <></>}
            </tr>
            {!isVickiEditMode && <tr data-testid="tr-head-row-1">
              <th data-testid="th-auction">Auction</th>
              <th data-testid="th-inventory-access">Inventory Access</th>
              <th data-testid="th-group-code">Group Code</th>
              <th data-testid="th-seller-number">Seller Number</th>
            </tr>}
          </thead>
          <tbody>
            {isVickiUser ? (
              <VickiUserDetail 
                data={userDetail}
                isEditable={isEditable}
                onToggleActivation={handleToggleActivation}
                onToggleEditMode={(value) => setIsVickiEditMode(value)} />
            ) : (
              <OktaUserDetail
                data={userDetail}
                isEditable={isEditable} 
                onToggleActivation={handleToggleActivation}
                onToggleEditMode={(value) => setIsOktaEditMode(value)} 
                isVEManagerRole={isVEManagerRole}
                isAdminRole={isAdminRole}
                />
            )}
          </tbody>
        </table>
        {isVickiEditMode && 
        <div className="link-service-now">
          <span className="mr-1">
            <IconSvg glyph="info-o" color={COLOR.BLUE} height="20" /> 
          </span>
          <span data-testid="link-service-now">
            Please use the <a
              data-testid="hyperlink-service-now"
              className="link-hyper"
              target="_blank"
              rel="noreferrer"
              href={SERVICE_NOW_URL}
            >
            link
          </a> to request changes to access for Vicki/Client</span>
        </div>
        }
      </div>
    </div>
  )
}

UserDetail.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  isVEManagerRole: PropTypes.bool,
  isAdminRole: PropTypes.bool
  
}

export default UserDetail
