import React, { useState, useEffect } from 'react'
import { Tabs, TabPane, Message , Button } from '@prism/library'
import { useSelector, useDispatch } from 'react-redux'
import { capitalize, isEmpty } from 'lodash'

import { Constants, ActionHelper } from '../utils'
import * as TYPEUSER from '../store/actions/actionTypes'
import * as ACTIONS from '../store/actions'

import UserManagementService from '../services/userManagementService'
import { SelectFilterComponent, TabPaneTableComponent,ButtonIcon } from '../components/BasicComponent'
import { 
  DEFAULT_PRODUCT_VALUE,
  IS_NEW_SCHEMA
} from '../utils/constants'
import { mappingObjectFilterTable, reIndexingUserList } from "../utils/actionHelper"

const defaultFilterParams = { product: IS_NEW_SCHEMA ? DEFAULT_PRODUCT_VALUE : '', role: '', location: '' }
const defaultFilterOptions = { options: [], errorMessage: '' }
const defaultFilterData = { data: [], errorMessage: '', next: '', previous: '' }

export default function FilterTable() {
  const dispatch = useDispatch()
  const [filterParams, setFilterParams] = useState(defaultFilterParams)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [locationOptions, setLocationOptions] = useState(defaultFilterOptions)
  const [roleOptions, setRoleOptions] = useState(defaultFilterOptions)
  const [activatedUsers, setActivatedUsers] = useState(defaultFilterData)
  const [deactivatedUsers, setDeactivatedUsers] = useState(defaultFilterData)
  const isDisabledClearFilter = ActionHelper.removeObjectKey(filterParams)
  const defaultParams = isActive => IS_NEW_SCHEMA ? `isActive=${isActive}&product=${DEFAULT_PRODUCT_VALUE}` : `isActive=${isActive}`
  const dataUserActivate = useSelector(state => state.filterTable.dataUserActivate)
  const dataUserDeactivate = useSelector(state => state.filterTable.dataUserDeactivate)
  const dataUserDetail = useSelector(state => state.users.dataUser)
  const { isEditUser = false } = dataUserDetail
  const [isShowTable, setIsShowTable] = useState(true)

  const getDefaultUser = () => {
    getUsers(defaultParams(true),true)
    getUsers(defaultParams(false),false)
  }

  useEffect(() => {
      setIsShowTable(isEditUser || isEmpty(dataUserDetail))
  },[dataUserDetail])

  useEffect(()=>{
    getDefaultUser()
  }, [])

  useEffect(() => {
    setActivatedUsers({ ...defaultFilterData, 
      data: dataUserActivate.data, 
      next: dataUserActivate?.next, 
      previous:dataUserActivate?.previous, 
      errorMessage: dataUserActivate.errorMessage})
  }, [dataUserActivate])

  useEffect(() => {
    setDeactivatedUsers({ ...defaultFilterData, 
      data: dataUserDeactivate.data, 
      next: dataUserDeactivate?.next, 
      previous:dataUserDeactivate?.previous, 
      errorMessage: dataUserDeactivate.errorMessage})
  },[dataUserDeactivate])


  const setValueParams = value => {
    if(value === null || value === ""){
      return undefined
    }
    return value
  }

  const getUsers = async(queryString, isActive) => {
    localStorage.setItem(isActive ? "activatedParams" : "deactivatedParams", JSON.stringify(queryString))
    const type = isActive ? TYPEUSER.GET_DATA_USER_ACTIVE_TAB : TYPEUSER.GET_DATA_USER_DEACTIVE_TAB
    dispatch(ACTIONS.actionsPending(type.PENDING))
    try {
      const { data } = await UserManagementService.filterUsers(queryString)
      if (Array.isArray(data.items)) {
        const flattenItems = mappingObjectFilterTable(data.items)
        const resultObj = { ...defaultFilterData, 
          data: flattenItems, 
          next: data?.next, 
          previous: data?.previous,
          isAscending: true,
          totalCount: data.totalCount || 0
        }
        resultObj.data = reIndexingUserList(resultObj.data)
        dispatch(ACTIONS.actionsSuccess(type.SUCCESS, resultObj))
      }
    } catch (error) {
      const { status } = error.response || {}
      if (status === 500) {
        dispatch(ACTIONS.actionsFailure(type.FAILURE,  Constants.SERVICE_UNAVAILABLE))
      }
    }
  }

  const getLocations = async () => {
    try {
      const { data } = await UserManagementService.getAuctionOptions()
      let locationOptions = data.items.map((item) => 
        ({ value: item.locationCode, label: item.locationName })
      )
      locationOptions = [{label: Constants.ALL_AUCTIONS,value: Constants.ALL_AUCTIONS_CODE},...locationOptions]
      setLocationOptions({ ...defaultFilterOptions, options: locationOptions })
    } catch (error) {
      const { status } = error.response || {}
      if (status === 500) {
        setLocationOptions({ ...defaultFilterOptions, errorMessage: Constants.ERROR_FETCHING_DATA })
      }
    }
  }

  const getRoles = async () => {
    try {
      const { data } = await UserManagementService.getRoles()
      const roleOptions = data.items.map((item) => 
        ({ value: item.name, label: item.displayName })
      )
      setRoleOptions({ ...defaultFilterOptions, options: roleOptions })
    } catch (error) {
      const { status } = error.response || {}
      if (status === 500) {
        setRoleOptions({ ...defaultFilterOptions, errorMessage: Constants.ERROR_FETCHING_DATA })
      }
    }
  }

  const renderErrorMessage = () => {
    if (activeTabIndex) {
      return (
        <Message className="error-filter" isOpen={deactivatedUsers.errorMessage !== "" ? true : false} color="danger">
          {deactivatedUsers.errorMessage}
        </Message>
      )
    }
    return (
      <Message className="error-filter" isOpen={activatedUsers.errorMessage !== "" ? true : false} color="danger">
        {activatedUsers.errorMessage}
      </Message>
    )
  }

  const handleSwitchTab = (e) => setActiveTabIndex(e.activeIndex)

  const handleChangeFilter = (event, filterType) => {
    const defaultProductParam = IS_NEW_SCHEMA ? (filterType === 'product') ? DEFAULT_PRODUCT_VALUE : '' : '' 
    setFilterParams({ ...filterParams, [`${filterType}`]: event ? event.value : defaultProductParam })
  }

  const handlePagination = async (url) => {
    if (!url) return false
    const stringParams = url.split('?')

    getUsers(stringParams[1], !activeTabIndex)
  }

  const setObjParams = (isActive) => {
    let params = {...filterParams}
    if(filterParams['location'] === Constants.ALL_AUCTIONS_CODE){
      delete params.location
      params = {...params, allAuctions: true}
    }
    const newParams = {
      isActive,
      product: setValueParams(params.product),
      role: setValueParams(params.role),
      location: setValueParams(params.location),
      allAuctions: setValueParams(params.allAuctions)
    }
    return newParams
  }

  const handleFilterUser = () => {
    const activatedParams = ActionHelper.objectToQueryString(setObjParams(true))
    const deactivatedParams = ActionHelper.objectToQueryString(setObjParams(false))

    getUsers(activatedParams,true)
    getUsers(deactivatedParams,false)
  }

  const handleClearFilterUser = () => {
    getDefaultUser()
    setFilterParams(defaultFilterParams)
  }

  const handleSortActivatedUser = (isAscending) => {
    dataUserActivate.isAscending = isAscending
    dataUserActivate.data = reIndexingUserList(dataUserActivate.data)
    dispatch(ACTIONS.actionsSuccess(TYPEUSER.GET_DATA_USER_ACTIVE_TAB.SUCCESS, 
      { ...dataUserActivate }
    ))
  }

  const handleSortDeactivatedUser = (isAscending) => {
    dataUserDeactivate.isAscending = isAscending
    dataUserDeactivate.data = reIndexingUserList(dataUserDeactivate.data)
    dispatch(ACTIONS.actionsSuccess(TYPEUSER.GET_DATA_USER_DEACTIVE_TAB.SUCCESS, 
      { ...dataUserDeactivate }
    ))
  }

  return (
    <div className="panel-outline">
      <div className='cursor-pointer d-flex justify-content-between align-items-center text-secondary filter-table'
          data-testid="filter-table-collapse"
          onClick={() => setIsShowTable(prev => !prev)}>
        <h2 className='prism-h2 mb-0'>Users</h2>
        <i className={`icon prism-icon-chevron-${isShowTable ? 'up':'down'}-light icon-header-filter-table`} />
      </div>
      <div className={`collapse ${isShowTable ? 'show':''}`}>
        {renderErrorMessage()}
        <div className="d-flex align-items-center">
          <SelectFilterComponent
            filterBox={capitalize(Constants.FILTER_TYPE.PRODUCT)}
            data={{ options: Constants.OPTION_FILTER_SELECT.PRODUCT, errorMessage: '' }}
            onChange={(e) => {
              handleChangeFilter(e, Constants.FILTER_TYPE.PRODUCT)
            }}
            value={Constants.OPTION_FILTER_SELECT.PRODUCT.filter(option => option.value === filterParams.product)}
          />
          <SelectFilterComponent
            filterBox={capitalize(Constants.FILTER_TYPE.ROLE)}
            data={roleOptions}
            onChange={(e) => {
              handleChangeFilter(e, Constants.FILTER_TYPE.ROLE)
            }}
            onFocus={!roleOptions.options.length ? getRoles : () => {}}
            value={roleOptions.options.filter(option => option.value === filterParams.role)}
          />
          <SelectFilterComponent
            filterBox={capitalize(Constants.FILTER_TYPE.LOCATION)}
            data={locationOptions}
            onChange={(e) => {
              handleChangeFilter(e, Constants.FILTER_TYPE.LOCATION)
            }}
            onFocus={!locationOptions.options.length ? getLocations : () => {}}
            value={locationOptions.options.filter(option => option.value === filterParams.location)}
          />
          <Button id="btn-filter" className="btn-filter mr-2" onClick={handleFilterUser}>Filter</Button>
          <Button id="btn-clear-filter" disabled={isEmpty(isDisabledClearFilter)} onClick={handleClearFilterUser}>Clear Filters</Button>
        </div>
        <Tabs key={Math.random()} className="mt-2" id="tab-filter-table" activeIndex={activeTabIndex} onChange={handleSwitchTab}>
          <TabPane id="tabpane-activate-filter-table" label={`${Constants.ACTIVATED_USERS_LABEL} (${dataUserActivate.totalCount || 0})`}>
            <TabPaneTableComponent dataTestId="activate" data={activatedUsers.data}
              onSort={handleSortActivatedUser} isAscending={dataUserActivate.isAscending} />
          </TabPane>
          <TabPane id="tabpane-deactivate-filter-table" label={`${Constants.DEACTIVATED_USERS_LABEL} (${dataUserDeactivate.totalCount || 0})`}>
            <TabPaneTableComponent dataTestId="deactivate" data={deactivatedUsers.data}
              onSort={handleSortDeactivatedUser} isAscending={dataUserDeactivate.isAscending} />
          </TabPane>
        </Tabs>
        <div className='text-right'>
            <ButtonIcon
              id="button-previous"
              colorBtn="secondary"
              className="btn-pagination mt-1"
              color={Constants.COLOR.WHITE}
              glyph="chevron-left"
              height="15"
              sizeBtn="sm"
              onClick={() => {
                const previous = activeTabIndex ? deactivatedUsers.previous : activatedUsers.previous
                handlePagination(previous)
              }}
              disabled={activeTabIndex ? !deactivatedUsers.previous : !activatedUsers.previous}
            />
            <ButtonIcon
              id="button-next"
              colorBtn="secondary"
              className="btn-pagination mt-1 ml-1"
              color={Constants.COLOR.WHITE}
              glyph="chevron-right"
              height="15"
              sizeBtn="sm"
              onClick={() => {
                const next = activeTabIndex ? deactivatedUsers.next : activatedUsers.next
                handlePagination(next)
              }}
              disabled={activeTabIndex ? !deactivatedUsers.next : !activatedUsers.next}
            />
        </div>
      </div>
  </div>
  )
}

