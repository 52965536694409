import React from 'react'
import PropTypes from 'prop-types'

const BlockedIconCell = ({
  colSpan = 1,
  dataTestId = 'td-global-access-0'
}) => {
  return (
    <td className="disable-column" data-testid={dataTestId} colSpan={colSpan}>
      
    </td>
  )
}

BlockedIconCell.propTypes = {
  colSpan: PropTypes.number,
  dataTestId: PropTypes.string
}

export default BlockedIconCell
