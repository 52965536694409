import React, { Fragment, useState } from 'react'
import { Constants, ActionHelper } from '../../../utils'
import { map } from 'lodash'
import { 
  ButtonIcon,
  SwitchComponent,
  ConfirmModalComponent
} from '../../BasicComponent'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { IconSvg } from '@prism/icon'
import { searchDataUser } from '../../../store/actions/searchUser'
import * as Constant from "../../../utils/constants"
import UserManagementService from '../../../services/userManagementService'
import { removeFalsyValues } from '../../../utils/actionHelper'

const {
  handleReloadPage
} = ActionHelper
const { DEFAULT_TYPE_VICKI, LIST_OPTIONS_OF_SEARCH_USER, LABEL_CLIENT, LABEL_TEAM_MEMBER, IS_NEW_SCHEMA  } = Constant
export default function BodyTable(props) {
  const dispatch = useDispatch()
  const { dataTestId, data } = props
  const [position, setPosition] = useState(-1)
  const [statusMessage, setStatusMessage] = useState('')
  const [toggleModal, setToggleModal] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const {isActive = false, userId = "", emailId = "", product = "", userType = "", fullName = "" } = userInfo || {}
  const [isError ,setIsError] = useState(false)
  const [indexInvalid, setIndexInvalid] = useState(-1)
  const userInfoStr = localStorage.getItem('userInfo')
  const isAdmin = userInfoStr && JSON.parse(userInfoStr)?.isAdmin || false

  const displayInventory = (value) => {
    if (value === undefined) return ''
    return (
      <div key={value ? 1 : 2}>
        {value ? Constants.DEFAULT_ALL_INVENTORY : Constants.DEFAULT_MY_INVENTORY}
      </div>
    )
  }

  const handleEditUser = item => {
    window.scroll({
      top: 0,
      behavior:'smooth'
    })
    const userType = item?.userType || null
    if (!userType) return false
    const valueSearch = userType === DEFAULT_TYPE_VICKI ? item.userId : item.emailId
    dispatch(searchDataUser({
      userType: userType === DEFAULT_TYPE_VICKI ? LIST_OPTIONS_OF_SEARCH_USER[0] : LIST_OPTIONS_OF_SEARCH_USER[1] ,
      userValue: encodeURIComponent(valueSearch)
    }, Constants.ACTION_WITH_BTN_SEARCH[1]))
  }

  const renderItemList = (arr = []) => {
    if(!arr.length) return "";
    return map(arr, (word, index) => <p key={index}>{word}</p>)
  }

  const renderAuctionAccess = (item, isValidData = true) => {
    if(item?.allInventoryAccess === undefined || item?.allInventoryAccess === null) return "";
    return (item?.allInventoryAccess && isValidData) ? Constants.ALL_AUCTIONS : ActionHelper.displayLocation(item?.authorizedToAccess[0]?.locationName, item?.authorizedToAccess[0]?.locationCode)
  }

  const handleChangeToggle = user => {
    setUserInfo(user)
    setToggleModal(true)
  }

  const renderConfirmMessage = () => {
    if (isError) return statusMessage
    return isActive
      ? `${Constants.CONFIRM_MESSAGE.DEACTIVATED} ${fullName}?`
      : `${Constants.CONFIRM_MESSAGE.ACTIVATED} ${fullName}?`
    };

  const handleSubmitToggle = async() => {
    try {
      const paramsData = {
        isActive: !isActive,
        userType,
        product: product === "" ? Constant.DEFAULT_PRODUCT_VALUE : product
      }
      if (userType === DEFAULT_TYPE_VICKI) {
        paramsData['userId'] = encodeURIComponent(userId)
      }
      else {
        let emailParts = emailId.split('@')
        paramsData['emailId'] = `${encodeURIComponent(emailParts[0])}@${emailParts[1]}`
      }
      const updateUserData = await UserManagementService.activateDataUser(paramsData)
      if(updateUserData){
        setIsError(false)
        handleReloadPage()
      }
    } catch (error) {
      const { status } = error.response || {}
      if (status === 500 || status === 400) {
        setStatusMessage(Constants.ERROR_SOMETHING_WENT_WRONG)
      } else if (status === 404) {
        setStatusMessage(`${userId} ${Constants.NOT_FOUND}`)
      }
      setIsError(true)
    }
  }
  
  return (
    <>
    <tbody data-testid={`${dataTestId}-tbody-filter-table`}>
      {data?.length ? map(data, (item, index) => {
        const subRowSpan = item?.authorizedToAccess?.length || 1
        let noOfAta = map(data, (i) => i.id === item.id && ((i.authorizedToAccess && i.authorizedToAccess.length) ? i.authorizedToAccess.length : 1))
        let rowSpan = removeFalsyValues(noOfAta).reduce((total, num) => total + num)
        const lastLoginValue = item.lastLoginDate ? new Date(item.lastLoginDate) : null
        const { isValidData = true } = item
        return (
          <Fragment key={index}>
            <tr data-testid={`tr-row-${item.userIndex}-${item.productIndex}-0`}
              className={`${(item.id === data[position]?.id) ? 'hover-row-on-table':''}`}
              onMouseOver={() => setPosition(index)} 
              onMouseOut={() => setPosition(-1)}
            >
              {index === 0 || index > 0 && (item.id && item.id !== data[index - 1].id) ? 
              <>
                <td data-testid={`td-user-type-${item.userIndex}-${item.productIndex}-0`} rowSpan={rowSpan}>
                  {item?.userType? item.userType === DEFAULT_TYPE_VICKI? LABEL_CLIENT: LABEL_TEAM_MEMBER: ""}
                </td>
                <td data-testid={`td-name-${item.userIndex}-${item.productIndex}-0`} rowSpan={rowSpan}>{item.fullName}</td>
                <td data-testid={`td-email-network-${item.userIndex}-${item.productIndex}-0`} rowSpan={rowSpan}>
                  {item.id}
                </td>
              </> : <></>}
              <td data-testid={`td-product-${item.userIndex}-${item.productIndex}-0`} rowSpan={subRowSpan}>
                {ActionHelper.formatProductName(item.product,'filterTable')}
              </td>
              <td data-testid={`td-auction-access-${item.userIndex}-${item.productIndex}-0`}>
                {renderAuctionAccess(item, isValidData)}
              </td>
              <td data-testid={`td-inventory-access-${item.userIndex}-${item.productIndex}-0`}>
                {displayInventory(item?.authorizedToAccess?.[0]?.allInventoryAccess)}
              </td>
              <td data-testid={`td-group-code-${item.userIndex}-${item.productIndex}-0`}>
                {renderItemList(item?.authorizedToAccess?.[0]?.groupCodes)}
              </td>
              <td data-testid={`td-seller-number-${item.userIndex}-${item.productIndex}-0`}>
                {renderItemList(item?.authorizedToAccess?.[0]?.sellerNumbers)}
              </td>
              <td data-testid={`td-role-${item.userIndex}-${item.productIndex}-0`} rowSpan={subRowSpan}>{item?.role}</td>
              <td data-testid={`td-last-login-date-${item.userIndex}-${item.productIndex}-0`} rowSpan={subRowSpan}>
                {lastLoginValue?.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit', hour12: false })} 
                <br />
                {lastLoginValue?.toLocaleDateString('en', {day: '2-digit', month: '2-digit', year: 'numeric' })}
              </td>              
              <td data-testid={`td-edit-${item.userIndex}-${item.productIndex}-0`} rowSpan={subRowSpan}>
                {isValidData ? (
                  <ButtonIcon
                   id={`button-edit-table-filter-${index}`}
                   className="btn-edit-action"
                   color={Constants.COLOR.DARK}
                   glyph="details"
                   height="25"
                   onClick={() => handleEditUser(item)}
                   disabled={!IS_NEW_SCHEMA} />
                ) : ' '}
              </td>
              <td data-testid={`td-activate-${item.userIndex}-${item.productIndex}-0`} rowSpan={subRowSpan}>
                {isValidData ? (
                  <SwitchComponent
                    id={`switch-user-${index}`}
                    dataTestId="switch-activate-user"
                    className="align-center" 
                    onChange={() => handleChangeToggle(item)}
                    value={item.isActive}
                    disabled={!isAdmin || !IS_NEW_SCHEMA} />
                ) : (
                  <div className={`case-invalid-data ${indexInvalid === index ? ' show' : ''}`} 
                    data-testid={`${dataTestId}-invalid-data-icon-${index}-0`}
                    onMouseOver={() => setIndexInvalid(index)} 
                    onMouseOut={() => setIndexInvalid(-1)}>
                    <IconSvg glyph="error-triangle"
                      height="21" />
                    <p data-testid={`${dataTestId}-invalid-data-text-${index}-0`}>Invalid data</p>
                  </div>
                )}
              </td>
            </tr>
            {map(item?.authorizedToAccess, (subItem, subIndex) => {
              return !!subIndex && (
                <tr key={subIndex} data-testid={`tr-row-${item.userIndex}-${item.productIndex}-${subIndex}`}
                  className={`${(item.id === data[position]?.id) ? 'hover-row-on-table':''}`}
                  onMouseOver={() => setPosition(index)} 
                  onMouseOut={() => setPosition(-1)}
                >
                  <td data-testid={`td-auction-access-${item.userIndex}-${item.productIndex}-${subIndex}`}>
                    {ActionHelper.displayLocation(subItem?.locationName, subItem?.locationCode)}
                  </td>
                  <td data-testid={`td-inventory-access-${item.userIndex}-${item.productIndex}-${subIndex}`}>
                    {displayInventory(subItem?.allInventoryAccess)}
                  </td>
                  <td data-testid={`td-group-code-${item.userIndex}-${item.productIndex}-${subIndex}`}>
                    {renderItemList(subItem?.groupCodes)}
                  </td>
                  <td data-testid={`td-seller-number-${item.userIndex}-${item.productIndex}-${subIndex}`}>
                    {renderItemList(subItem?.sellerNumbers)}
                  </td>
                </tr>
              )
            })}
          </Fragment>
        )
      }) : <tr data-testid={`${dataTestId}-tr-nodata-filter-table`}>
        <td className="text-center" data-testid={`${dataTestId}-td-nodata-filter-table`} colSpan={12}>{Constants.NO_DATA_AVAILABLE}</td>
      </tr>}
    </tbody>
    <ConfirmModalComponent
      toggleModal={toggleModal}
      isErrorType={isError}
      headerText={!isError ? `Warning` : `Error`}
      bodyText={renderConfirmMessage()}
      fullName={fullName}
      btnConfirmText={!isError ? (isActive ? `Deactivate` : `Activate`) : `OK`}
      handleConfirmFunc={() =>!isError ? handleSubmitToggle() : handleReloadPage()}
      handleCloseFunc={() => setToggleModal(false)} />
    </>

  )
}

BodyTable.propTypes = {
  dataTestId: PropTypes.string,
  data: PropTypes.array
}

