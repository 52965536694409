import { isEmpty, isObject, forEach, capitalize, map, join } from 'lodash'
import { DEFAULT_PRODUCT_LABEL, DEFAULT_TYPE_OKTA  } from './constants'

export const createActionSet = (actionName, extraField = []) => {
  const actionType = {
    NAME: actionName,
    PENDING: `${actionName}_PENDING`,
    SUCCESS: `${actionName}_SUCCESS`,
    FAILURE: `${actionName}_FAILURE`
  }
  extraField.forEach((field) => {
    actionType[field] = `${actionName}_${field}`
  })

  return actionType
}
export const getErrorMessage = ({ message, statusCode, code }) => {
  let result = ''
  let parseCode = code || statusCode
  parseCode = String(parseCode)
  switch (parseCode) {
    // 4xx client errors 
    case '200':
      result = message || ''
      break
    case '400':
      result = message || 'Bad Request'
      break
    case '401':
      location.reload()
      break
    case '403':
      result = message || 'Forbidden'
      break
    case '404':
      result = message || 'Not Found'
      break
    // 5xx server errors
    case '500':
      result = message || 'Internal Server Error'
      break
    case '502':
      result = 'Bad Gateway'
      break
    case '503':
      result = 'Service Unavailable'
      break
    case '504':
      result = 'Gateway Timeout'
      break
    default:
      result = 'Service Unavailable'
  }
  return result
}
export const parseErrors = (errors) => {
  let parseErrors
  const { status, data } = errors?.response || {}
  try {
    parseErrors = JSON.parse(data)
  } catch (error) {
    parseErrors = data
  }
  const { errors: errs = [] } = parseErrors || {}
  const { message, statusCode = status, code } = errs[0] || {}
  return getErrorMessage({ message, statusCode, code })
}

export const hasSpecialCharacters = (text) => {
  let format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
  return text.match(format) ? true : false
}

export const removeEmptyParams = (params) => {
  if (!params) return []
  params.forEach(item => {
    Object.keys(item).forEach(key => {
      if (Array.isArray(item[key]) && item[key].length) {
        item[key] = removeFalsyValues(item[key])
      }
      if (item[key] === null || (isObject(item[key]) && isEmpty(item[key]))) {
        delete item[key]
      }
    })
  })
  return params
}

export const removeFalsyValues = (arr) => {
  if (!arr) return []
  return arr.filter(k => k)
}

export const removeSpace = (str) =>{
  return str.replace(/\s/g, '')
}

export const removeObjectKeys = (obj, propsList) => {
  forEach(propsList, (prop) => {
    delete obj[prop]
  })
}

export const formatProductName = (text,type='userTable') => {
  if(!text && type !== 'userTable' && typeof text !== 'string') return '';
  if(!text?.length) return DEFAULT_PRODUCT_LABEL
 
  const words = text ? text.replace(/_/g, ' ').split(' ') : []
  const newWords = map(words, word => capitalize(word.toLowerCase()))
  return newWords.join(' ')
 
}

export const displayLocation = (name, code) => {
  if (!code && !name) return ''
  if (!code) return name
  if (!name) return code
  return join([name, code], ' - ')
}

export const objectToQueryString = (obj) => {
  return Object.keys(obj)
  .map(key => {
    if (obj[key] !== undefined) {
      return `${key}=${obj[key]}`
    }
  }).filter(e => e !== undefined).join('&')
}

export const removeObjectKey = obj =>{
  Object.keys(obj).forEach((item) => {
   if(obj[item] === null || obj[item] === undefined || obj[item] === "") {
     delete obj[item]
   }
 })
 return obj
}

export const handleReloadPage = () => window.location.reload()

export const mappingObjectFilterTable = (items) => {
  let result = []
  items.forEach((item) => {
    item.applications.forEach((i) => {
      result.push({
        ...i,
        fullName: item.fullName || '',
        userId: item.userId,
        emailId: item.emailId,
        userType: item.userType,
        product: i.name,
        role: i?.role ? i.role.displayName : '',
        id: (item.userType === DEFAULT_TYPE_OKTA ? item.emailId : item.userId) || item.userId,
        isValidData: i.isValid
      })
    })
  })
  return result
}

export const reIndexingUserList = (users) => {
  users.forEach((v, i) => {
    if (i === 0) {
      users[i]['userIndex'] = 0
      users[i]['productIndex'] = 0
    }
    else if (users[i]['id'] === users[i - 1]['id']) {
      users[i]['userIndex'] = users[i - 1]['userIndex']
      users[i]['productIndex'] = users[i - 1]['productIndex'] + 1
    }
    else {
      users[i]['userIndex'] = users[i - 1]['userIndex'] + 1
      users[i]['productIndex'] = 0
    }
  })
  return users
}
