import update from 'immutability-helper'
import { Constants } from '../../utils'
import * as ACTION_TYPES from '../actions/actionTypes'

const { ERROR_FETCHING_DATA } = Constants

export const initialState = {
  auctions: [],
  isPending: false,
  isSuccess: false,
  isError: false,
  errorMessage: ''
}

const AuctionReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ACTION_TYPES.GET_AUCTIONS.PENDING: {
      return update(state, {
        isPending: {
          $set: true
        },
        isSuccess: {
          $set: false
        },
        isError: {
          $set: false
        },
        errorMessage: {
          $set: ''
        }
      })
    }
    case ACTION_TYPES.GET_AUCTIONS.SUCCESS: {
      return update(state, {
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        isError: {
          $set: false
        },
        errorMessage: {
          $set: ''
        }
      })
    }
    case ACTION_TYPES.GET_AUCTIONS.NAME: {
      return update(state, {
        auctions: {
          $set: payload
        },
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        isError: {
          $set: false
        },
        errorMessage: {
          $set: ''
        }
      })
    }
    case ACTION_TYPES.GET_AUCTIONS.FAILURE: {
      let errorMessage = '';
      if (payload === 500) {
        errorMessage = ERROR_FETCHING_DATA
      }
      return update(state, {
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: false
        },
        isError: {
          $set: true
        },
        errorMessage: {
          $set: errorMessage
        }
      })
    }
    default:
      return state
  }
}

export default AuctionReducer
