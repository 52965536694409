import React from 'react'
import PropTypes from 'prop-types'
import { 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCancel,
  ModalOkay
} from '@prism/library'

const ConfirmationModal = ({
  isOpen = false,
  isError = false,
  title,
  content,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel
}) => {
  return (
    <Modal 
      isOpen={isOpen}
      backdrop={'static'}
      keyboard={false}
    >
      <ModalHeader data-testid="mod-title">{title}</ModalHeader>
      <ModalBody data-testid="mod-content">{content}</ModalBody>
      <ModalFooter data-testid="mod-action">
        {!isError && <ModalCancel data-testid="btn-mod-cancel" text={cancelLabel} 
          onClick={onCancel} className="btn-outline-secondary" />}
        <ModalOkay data-testid="btn-mod-ok" text={confirmLabel} 
          onClick={onConfirm} className="btn-secondary" />
      </ModalFooter>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  isError: PropTypes.bool,
  title: PropTypes.any,
  content: PropTypes.any,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default ConfirmationModal
