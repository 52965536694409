import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './store'
import './styles/index.scss'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary'

const store = configureStore()

export const ReactFragment = <React.Fragment>
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
</React.Fragment>

export const rootElement = document.getElementById('root')

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    ReactFragment,
    rootElement
  )
})
