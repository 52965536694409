import { combineReducers } from 'redux'
import UsersReducer from './UsersReducer'
import RoleReducer from './RoleReducer'
import AuctionReducer from './AuctionReducer'
import FilterTableReducer from "./FilterTableReducer"

export default combineReducers({
  users: UsersReducer,
  roles: RoleReducer,
  auctions: AuctionReducer,
  filterTable: FilterTableReducer
})
