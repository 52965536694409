import { Request, Api } from '../utils'
const logout = () =>
  Request({
    url: `${Api.USER_MANAGEMENT_API_URL}/logout`,
    method: 'GET'
  })

const getUserDetail = () =>
  Request({
    url: Api.GET_USER_DETAIL,
    method: 'GET'
  })

const searchDataUser = (params) => {
  const url = `${Api.USER_MANAGEMENT_API_URL}/viAudit/userAccessManagement/userDetails?${params.userId ? 'userId' : 'emailId'}=${params.userId || params.emailId}&loginType=${params.loginType}`
  return Request({
    url,
    method: 'GET',
  })
}

const getAuctionOptions = () =>
  Request({
    url: Api.GET_AUCTION_OPTIONS,
    method: 'GET'
  })
const getRolesOption = (productName) =>
  Request({
    url: `${Api.GET_ROLES}?application=${productName}`,
    method: 'GET'
  })

const getRoles = () =>
  Request({
    url: Api.GET_ROLES,
    method: 'GET'
  })

const updateDataUser = (params) => {
  return Request({
    url: Api.SEARCH_VICKI_OKTA_USER,
    method: 'PUT',
    data: params
  })
}

const activateDataUser = (params) => {
  return Request({
    url: Api.ACTIVATE_VICKI_OKTA_USER,
    method: 'PUT',
    data: params
  })
}

const filterUsers = (queryString) => {
  return Request({
    url: `${Api.FILTER_USERS}?${queryString}`,
    method: 'GET'
  })
}

export default {
  logout,
  getUserDetail,
  searchDataUser,
  getAuctionOptions,
  updateDataUser,
  getRolesOption,
  getRoles,
  activateDataUser,
  filterUsers
}
