import React, { useState, useEffect } from 'react'
import { Button, Input } from '@prism/library'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Dropdown from './Dropdown'
import { searchDataUser, dropdownSearchUser } from '../store/actions/searchUser'
import { LIST_OPTIONS_OF_SEARCH_USER, SEARCH_BY_USER_ID, SEARCH_BY_EMAIL_ADDRESS, DEFAULT_TYPE_VICKI } from "../utils/constants"


export default function SearchUser(props) {
  const dispatch = useDispatch()
  const { isEditUser = false, dataUser, isVEManagerRole = false } = props
  const [userType, setUserType] = useState(isVEManagerRole ? LIST_OPTIONS_OF_SEARCH_USER[1] : LIST_OPTIONS_OF_SEARCH_USER[0])
  const [userValue, setUserValue] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {  
    if(isEditUser){
      setUserType((dataUser.userType === DEFAULT_TYPE_VICKI) && !isVEManagerRole ? LIST_OPTIONS_OF_SEARCH_USER[0] :  LIST_OPTIONS_OF_SEARCH_USER[1] )
      setUserValue(dataUser.userType === DEFAULT_TYPE_VICKI ? dataUser.userId : dataUser.emailId)
    }
  },[dataUser])

  useEffect(() => {
    userValue.trim() !== '' ? setIsDisabled(false) : setIsDisabled(true)
  }, [userValue])

  const handleChangeDropdownUser = (value) => {
    setUserType(value)
    dispatch(dropdownSearchUser({ userType: value }))
  }
  const handleChangeUserValue = (value) => {
    setUserValue(value.target.value)
  }
  const handleSubmitSearchUser = () => {
    dispatch(searchDataUser({ userType, userValue:encodeURIComponent(userValue.trim()) }))
  }
  return (
    <div className="search-container">
      <div className="search-panel">
        <Dropdown
          tabIndex="1"
          data-testid="dropdown-user"
          defaultValue={userType}
          onChange={handleChangeDropdownUser}
          list={LIST_OPTIONS_OF_SEARCH_USER}
          isChecked={true}
          isDisabled={isVEManagerRole}
        />
        <Input.Interactive
          tabIndex="2"
          type="text"
          iconLeft="icon text-input__icon-left prism-icon-user"
          value={userValue}
          onChange={handleChangeUserValue}
          data-testid="form-search__user"
          className="custom-form__input"
          placeholder={userType === LIST_OPTIONS_OF_SEARCH_USER[0] ? SEARCH_BY_USER_ID : SEARCH_BY_EMAIL_ADDRESS}
          onKeyPress={event => event.key === 'Enter' && !isDisabled && handleSubmitSearchUser()}
        />
        <Button className="custom-form__btn" tabIndex="3" data-testid="button-search__panel" disabled={isDisabled} color="primary" onClick={handleSubmitSearchUser}>
          SEARCH
        </Button>
      </div>
    </div>
  )
}

SearchUser.propTypes = {
  isEditUser: PropTypes.bool,
  dataUser: PropTypes.object,
  isVEManagerRole: PropTypes.bool
} 