import axios from 'axios'

const Request = ({ url = '', method = 'get', params, data, headers = {} }) =>
  axios({
    url: `${url}`,
    method,
    data,
    params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...headers
    },
    withCredentials: true
  })

export default Request
