import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalCancel, ModalOkay } from '@prism/library'

export default function ConfirmModalComponent(props) {
    const { 
        id = "confirmModal", 
        toggleModal = false, 
        isErrorType = false, 
        headerText = "", 
        bodyText = "", 
        fullName = "", 
        btnConfirmText = "", 
        handleConfirmFunc = () => {}, 
        handleCloseFunc = () => {},
        nameText = "" 
    } = props
    const [isOpen, setIsOpen] = useState(toggleModal)

    useEffect(() => {
        setIsOpen(toggleModal)
    }, [toggleModal])

    const renderBodyText = (prbodyText, prfullName) => {
        if (prbodyText.includes(prfullName)) {
            let prbodyTextArr = prbodyText.split(prfullName)
            return (
                <span>{prbodyTextArr[0]}<b>{prfullName}</b>{prbodyTextArr[1]}</span>
            )
        }
        return <span><b>{nameText || ""}</b> {prbodyText}</span>
    }
    
    return (
        <Modal
            id={id}
            isOpen={isOpen}
            backdrop={'static'}
            keyboard={false}
        >
            <ModalHeader id="modal-header" data-testid="modal-header">{!isErrorType ? headerText : <span className="text-danger">{headerText}</span>}</ModalHeader>
            <ModalBody id="modal-body-confirm" data-testid="modal-body-confirm">
                {renderBodyText(bodyText, fullName)}
            </ModalBody>
            <ModalFooter>
                {!isErrorType ? <ModalCancel id="modal-btn-cancel" data-testid="modal-btn-cancel" outline  className="m-2" onClick={handleCloseFunc} /> : null}             
                <ModalOkay id="modal-btn-confirm" data-testid="modal-btn-confirm" className={`m-2 btn-secondary ${!isErrorType ? `btn-blue` : `btn-red`} `} text={btnConfirmText} onClick={handleConfirmFunc} />
            </ModalFooter>
        </Modal>
    )
}

ConfirmModalComponent.propTypes = {
    id: PropTypes.string,
    toggleModal: PropTypes.bool,
    isErrorType: PropTypes.bool,
    headerText: PropTypes.string,
    bodyText: PropTypes.string,
    fullName: PropTypes.string,
    btnConfirmText: PropTypes.string,
    handleConfirmFunc: PropTypes.func,
    handleCloseFunc: PropTypes.func,
    nameText:PropTypes.string
}
