export const USER_MANAGEMENT_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8888'
export const CLIENT_NAME = process.env.CLIENT_NAME || 'user_management_ui'
export const APPLICATION_ID = process.env.APPLICATION_ID || 'SMT'
export const GET_USER_DETAIL = `${USER_MANAGEMENT_API_URL}/userDetails`
export const AUTH_URL = `${USER_MANAGEMENT_API_URL}/authorize?clientName=${CLIENT_NAME}`
export const APPLICATION = 'smart_approve'

// USER
export const GET_AUCTION_OPTIONS = `${USER_MANAGEMENT_API_URL}/viAudit/locations`
export const SEARCH_VICKI_OKTA_USER = `${USER_MANAGEMENT_API_URL}/viAudit/userAccessManagement/userDetails`
export const ACTIVATE_VICKI_OKTA_USER = `${USER_MANAGEMENT_API_URL}/viAudit/userAccessManagement/user/activate`

// ROLES
export const GET_ROLES = `${USER_MANAGEMENT_API_URL}/viAudit/userAccessManagement/roles`

export const FILTER_USERS = `${USER_MANAGEMENT_API_URL}/viAudit/userAccessManagement/userDetails/search`
export const SEARCH_USER_DETAILS = `${USER_MANAGEMENT_API_URL}/viAudit/userAccessManagement/userDetails`
