import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from './middleware/loggerMiddleware'
import rootReducer from './reducers'

export const middlewares = [thunkMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares, loggerMiddleware)
const enhancers = [middlewareEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)

export default function configureStore (preloadedState) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composedEnhancers
  )
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }
  return store
}
