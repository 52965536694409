import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { COLOR, SORT_TYPE } from '../../../utils/constants';
import SortButton from './SortButton'

export default function HeaderTable(props) {
  const {dataTestId, onSort, isAscending } = props

  const [sortState, setSortState] = useState({
    isAscending: isAscending !== undefined ? isAscending : true,
    type: SORT_TYPE.NAME
  })

  const handleSort = (type) => {
    setSortState({ type, isAscending: !sortState.isAscending })
    onSort(!sortState.isAscending)
  }

  return (
    <thead className="text-capitalize">
      <tr>
        <th data-testid={`${dataTestId}-th-user-type-filter-table`} rowSpan={2}>User Type</th>
        <th data-testid={`${dataTestId}-th-name-filter-table`} rowSpan={2}>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-1">Name</span>
            <SortButton
              color={COLOR.WHITE}
              disableColor={COLOR.DARK_GRAY}
              idName={SORT_TYPE.NAME}
              onClick={() => handleSort(SORT_TYPE.NAME)}
              isAscending={sortState.isAscending} />
          </div>
        </th>
        <th data-testid={`${dataTestId}-th-email-network-filter-table`} rowSpan={2}>
          <div data-testid={`${dataTestId}-email-filter-table`}>Email/M.COM</div>
          <div data-testid={`${dataTestId}-network-id-filter-table`}>Network ID</div>
        </th>
        <th data-testid={`${dataTestId}-th-product-filter-table`} rowSpan={2}>
          Product
        </th>
        <th data-testid={`${dataTestId}-th-auction-access-filter-table`} rowSpan={2}>Auction Access</th>
      <th data-testid={`${dataTestId}-th-inventory-access-filter-table`} rowSpan={2}>Inventory Access</th>
      <th data-testid={`${dataTestId}-th-group-code-filter-table`} rowSpan={2}>Group Code</th>
        <th data-testid={`${dataTestId}-th-seller-number-filter-table`} rowSpan={2}>Seller Number</th>
        <th data-testid={`${dataTestId}-th-role-filter-table`} rowSpan={2}>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-1">Role</span>
          </div>
        </th>
        <th data-testid={`${dataTestId}-th-last-login-date-filter-table`} rowSpan={2}>Last Login Date</th>
        <th data-testid={`${dataTestId}-th-action-filter-table`} colSpan={2}>Action</th>
      </tr>
      <tr className="w-100">
        <th data-testid={`${dataTestId}-th-action-edit-filter-table`}>View</th>
        <th data-testid={`${dataTestId}-th-action-activate-filter-table`}>Activate</th>
      </tr>
    </thead>
  )
}

HeaderTable.propTypes = {
  dataTestId: PropTypes.string,
  onSort: PropTypes.func,
  isAscending: PropTypes.bool
}
