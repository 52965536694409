import { createActionSet } from '../../utils/actionHelper'

export const ACTION_PENDING = 'ACTION_PENDING'
export const ACTION_LOADER = createActionSet('ACTION_LOADER')

// USER
export const SEARCH_DATA_USER = createActionSet('USER/SEARCH_DATA_USER')
export const USER = createActionSet('USER', ['SEARCH', 'CREATE', 'UPDATE'])

//DROPDOWN
export const DROPDOWN_DATA_USER = createActionSet('USER/DROPDOWN_DATA_USER')
export const GET_AUCTIONS = createActionSet('GET_AUCTIONS')

//ROLES
export const GET_ERR_EXTERNAL_SERVICE = createActionSet('USER/GET_ERR_EXTERNAL_SERVICE')
export const GET_ROLES_BY_USER_TYPE = createActionSet('GET_ROLES_BY_USER_TYPE')

//FILTER TABLE
export const GET_DATA_USER_ACTIVE_TAB = createActionSet('FILTER/GET_DATA_USER_ACTIVE_TAB')
export const GET_DATA_USER_DEACTIVE_TAB = createActionSet('FILTER/GET_DATA_USER_DEACTIVE_TAB')
