import { filter } from 'lodash'
import { Constants } from '../../utils'
import { GET_ROLES_BY_USER_TYPE } from './actionTypes'
import userManagementService from '../../services/userManagementService'

const {
  DEFAULT_TYPE_VICKI,
  UNACCEPTED_VICKI_ROLES,
  UNACCEPTED_OKTA_ROLES
} = Constants

export const getRoles = async (dispatch, userType, productName) => {
  try {
    dispatch({
      type: GET_ROLES_BY_USER_TYPE.PENDING
    })
    const { data } = await userManagementService.getRolesOption(productName.toLowerCase())
    const { items } = data
    const unacceptedRoles = userType === DEFAULT_TYPE_VICKI ? 
      UNACCEPTED_VICKI_ROLES : 
      UNACCEPTED_OKTA_ROLES
    const filteredRoles = filter(items, (item) => 
      unacceptedRoles.indexOf(item.name) === -1
    )
    dispatch({
      type: GET_ROLES_BY_USER_TYPE.SUCCESS
    })
    dispatch({
      type: GET_ROLES_BY_USER_TYPE.NAME,
      payload: filteredRoles
    })
  }
  catch (error) {
    const { status } = error?.response
    dispatch({
      type: GET_ROLES_BY_USER_TYPE.FAILURE,
      payload: status
    })
  }
}
