import update from 'immutability-helper'
import * as ACTION_TYPES from '../actions/actionTypes'

export const initialState = {
  dataUserActivate: {
    data: [],
    previous: null,
    next: null,
    errorMessage: "",
    isAscending: true,
    totalCount: 0
  },
  dataUserDeactivate: {
    data: [],
    previous: null,
    next: null,
    errorMessage: "",
    isAscending: true,
    totalCount: 0
  },
  isPending: false,
  isSuccess: false,
  isError: false,
 
}

const FilterTableReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ACTION_TYPES.GET_DATA_USER_ACTIVE_TAB.PENDING: {
      return update(state, {
        isPending: {
          $set: true
        },
        isError: {
            $set: false
        },
        dataUserActivate: {
          $set: {
            data: [],
            previous: null,
            next: null,
            errorMessage: "",
            isAscending: true,
            totalCount: 0
          }
        }
      })
    }
    case ACTION_TYPES.GET_DATA_USER_ACTIVE_TAB.SUCCESS : {
      if (payload.isAscending) {
        payload.data.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1)
      }
      else {
        payload.data.sort((a, b) => (a.fullName < b.fullName) ? 1 : -1)
      }
      return update(state, {
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        isError: {
          $set: false
        },
        dataUserActivate: {
          $set: {
            data: payload.data,
            previous: payload?.previous || null,
            next: payload?.next || null,
            errorMessage: "",
            isAscending: payload.isAscending,
            totalCount: payload.totalCount
          }
        },
      })
     
    }
    case ACTION_TYPES.GET_DATA_USER_ACTIVE_TAB.FAILURE: {
      return update(state, {
        isPending: {
          $set: false
        },
        isError: {
          $set: true
        },
        isSuccess: {
          $set: false
        },
        dataUserActivate: {
          $set: {
            data: [],
            previous:  null,
            next:  null,
            errorMessage: payload,
            isAscending: true,
            totalCount: 0
          }
        }
      })
    }

    // ======================================DEACTIVE==================================
    case ACTION_TYPES.GET_DATA_USER_DEACTIVE_TAB.PENDING: {
        return update(state, {
          isPending: {
            $set: true
          },
          dataUserDeactivate: {
            $set: {
              data: [],
              previous:  null,
              next:  null,
              errorMessage: "",
              isAscending: true,
              totalCount: 0
            }
          },
          isError: {
              $set: false
          }
        })
      }
      case ACTION_TYPES.GET_DATA_USER_DEACTIVE_TAB.SUCCESS: {
        if (payload.isAscending) {
          payload.data.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1)
        }
        else {
          payload.data.sort((a, b) => (a.fullName < b.fullName) ? 1 : -1)
        }
        return update(state, {
          isPending: {
            $set: false
          },
          isSuccess: {
            $set: true
          },
          isError: {
            $set: false
          },
          dataUserDeactivate: {
            $set: {
                data: payload.data,
                previous: payload?.previous || null,
                next: payload?.next || null,
                errorMessage: "",
                isAscending: payload.isAscending,
                totalCount: payload.totalCount
            }
          }
        })
      }
      case ACTION_TYPES.GET_DATA_USER_DEACTIVE_TAB.FAILURE: {
        return update(state, {
          isPending: {
            $set: false
          },
          isError: {
            $set: true
          },
          isSuccess: {
            $set: false
          },
          dataUserDeactivate: {
            $set: {
                data: [],
                previous: null,
                next: null,
                errorMessage: payload,
                isAscending: true,
                totalCount: 0
            }
          }
        })
      }

    default:
      return state
  }
}
export default FilterTableReducer
