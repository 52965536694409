import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Constants } from '../../utils'
import { formatProductName, mapObjectToSelectOptions } from './utils/helper'
import { getRoles } from '../../store/actions/roleAction'
import { Button } from '@prism/library'
import { IconSvg } from '@prism/icon'
import Select from 'react-select'
import { isEmpty } from 'lodash'
import BlockedIconCell from "./BlockedIconCell";

const { 
  DEFAULT_ICON_SIZE,
  DEFAULT_TYPE_VICKI,
  DEFAULT_PRODUCT,
  DEFAULT_PRODUCT_VALUE,
  ERROR_FETCHING_DATA
} = Constants

const VickiUserForm = ({
  fullName,
  data,
  onConfirm,
  onCancel
}) => {
  const dispatch = useDispatch()
  const { name: productName, role } = data
  const roleOptions = useSelector(state => 
    mapObjectToSelectOptions(state.roles.rolesByUserType, 'name', 'displayName'))
  const hasLoadedRoleOptionsFailed = useSelector(state => state.roles.isError)

  const [productData, setProductData] = useState(data)

  useEffect(() => {
    getRoles(dispatch, DEFAULT_TYPE_VICKI, DEFAULT_PRODUCT_VALUE)
  }, [productData.name])

  const renderRoleInput = () => {
    const selectedRole = {
      value: productData?.role?.name || roleOptions[0]?.value,
      label: productData?.role?.displayName || roleOptions[0]?.label
    }
    return (
      <>
        <div className="text-danger mb-1">
          {!roleOptions.length && hasLoadedRoleOptionsFailed ? ERROR_FETCHING_DATA : ''}
        </div>
        <Select
          id="sbx-vicki-form-role"
          styles={{
            option: (provided) => ({
              ...provided,
              textAlign: "left",
              cursor: "pointer"
            })
          }}
          options={roleOptions} value={selectedRole}
          onChange={handleChangeRole} />
      </>
    )
  }

  const renderActionControls = () => (
    <div className="d-flex align-items-center justify-content-center">
      <Button 
        className="p-0 border-0 btn-icon pr-3"
        id="btn-vicki-form-confirm"
        onClick={() => handleSubmitForm(productData)}>
        <IconSvg
          className="button-icon"
          glyph="checkmark-circle"
          height={DEFAULT_ICON_SIZE.ACTION}
          color="secondary" />
      </Button>
      <Button 
        className="p-0 border-0 btn-icon pr-3"
        id="btn-vicki-form-cancel"
        onClick={handleCancelForm}>
        <IconSvg
          className="button-icon"
          glyph="cancel-circle-o"
          height={DEFAULT_ICON_SIZE.ACTION}
          color="secondary" />
      </Button>
    </div>
  )

  const handleChangeRole = (value) => {
    setProductData((prev) => ({ ...prev, role: { name: value.value, displayName: value.label } }))
  }

  const handleSubmitForm = (value) => {
    if (isEmpty(value?.role)) {
      value.role = {
        name: roleOptions[0].value,
        displayName: roleOptions[0].label
      }
    }
    onConfirm(value)
  }

  const handleCancelForm = () => {
    if (!isEmpty(role)) {
      onCancel()
    }
    else {
      setProductData((prev) => ({ ...prev, role: { name: roleOptions[0].value, displayName: roleOptions[0].label } }))
    }
  }

  return (
    <tr>
      <td data-testid="td-vicki-form-name">{fullName}</td>
      <td data-testid="td-vicki-form-product">{
        productName ? formatProductName(productName) : formatProductName(DEFAULT_PRODUCT)}</td>
      <BlockedIconCell data-testid="td-vicki-form-company" />
      <td data-testid="td-vicki-form-role" className="text-left">
        {renderRoleInput()}
      </td>
      <td>
        {renderActionControls()}
      </td>
    </tr>
  )
}

VickiUserForm.propTypes = {
  fullName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default VickiUserForm
