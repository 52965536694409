import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@prism/library'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="error-boundary-page">
          <Button id="btn-reload" onClick={() => window.location.reload()} outline className="m-2">Reload</Button>
          <h2>Something went wrong</h2>
          <p>Please try again</p>
        </div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
}
