import update from 'immutability-helper'
import * as ACTION_TYPES from '../actions/actionTypes'

export const initialState = {
  dataUser: {},
  isPending: false,
  isSuccess: false,
  isError: false,
  isTableError: true,
  errorMessage: ''
}

const UsersReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ACTION_TYPES.ACTION_PENDING: {
      return update(state, {
        isPending: {
          $set: true
        }
      })
    }
    case ACTION_TYPES.ACTION_LOADER.PENDING: {
      return update(state, {
        isPending: {
          $set: true
        },
        errorMessage: {
          $set: ''
        },
        dataUser: {
          $set: {}
        },
        isTableError: {
          $set: true
        }
      })
    }
    case ACTION_TYPES.ACTION_LOADER.SUCCESS: {
      return update(state, {
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        isError: {
          $set: false
        },
        dataUser: {
          $set: {}
        },
        isTableError: {
          $set: true
        }
      })
    }
    case ACTION_TYPES.ACTION_LOADER.FAILURE: {
      return update(state, {
        isPending: {
          $set: false
        },
        isError: {
          $set: true
        },
        isSuccess: {
          $set: false
        },
        errorMessage: {
          $set: ''
        },
        dataUser: {
          $set: {}
        },
        isTableError: {
          $set: true
        }
      })
    }
    case ACTION_TYPES.SEARCH_DATA_USER.SUCCESS: {
      return update(state, {
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        dataUser: {
          $set: payload
        },
        isError: {
          $set: false
        },
        isTableError: {
          $set: true
        },
      })
    }
    case ACTION_TYPES.SEARCH_DATA_USER.FAILURE: {
      return update(state, {
        isPending: {
          $set: false
        },
        isError: {
          $set: true
        },
        isSuccess: {
          $set: false
        },
        errorMessage: {
          $set: payload
        },
        isTableError: {
          $set: true
        },
      })
    }

    case ACTION_TYPES.DROPDOWN_DATA_USER.SUCCESS: {
      return update(state, {
        isError: {
          $set: false
        },
        dataUser: {
          $set: {}
        },
        isTableError: {
          $set: true
        },
      })
    }

    case ACTION_TYPES.GET_ERR_EXTERNAL_SERVICE.SUCCESS: {
      return update(state, {
        isPending: {
          $set: false
        },
        isSuccess: {
          $set: true
        },
        isError: {
          $set: false
        },
        isTableError: {
          $set: true
        },
      })
    }
    case ACTION_TYPES.GET_ERR_EXTERNAL_SERVICE.FAILURE: {
      return update(state, {
        isPending: {
          $set: false
        },
        isError: {
          $set: false
        },
        isSuccess: {
          $set: false
        },
        errorMessage: {
          $set: payload
        },
        isTableError: {
          $set: false
        },
      })
    }
    
    default:
      return state
  }
}
export default UsersReducer
