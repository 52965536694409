import React from 'react'
import { Button } from '@prism/library'
import PropTypes from 'prop-types'
import { IconSvg } from '@prism/icon'
import { Constants } from '../../utils'
export default function ButtonIcon(props) {
  const { 
    height = Constants.DEFAULT_ICON_SIZE.ACTION, 
    color, 
    colorBtn = "",
    glyph, 
    onClick = () => { }, 
    id, 
    className = "",
    disabled  = false,
    sizeBtn = ""
  } = props
  return (
    <Button
      size={sizeBtn}
      color={colorBtn}
      disabled={disabled}
      onClick={onClick}
      className={`p-0 border-0 ${className}`}
      id={id}
    >
      <IconSvg className='button-icon' glyph={glyph} height={height} color={color} />
    </Button>
  )
}

ButtonIcon.propTypes = {
  height: PropTypes.string,
  color: PropTypes.string,
  glyph: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  colorBtn: PropTypes.string,
  sizeBtn: PropTypes.string
}
