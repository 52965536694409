import React from 'react'
import ErrorBoundary from './components/ErrorBoundary'
import Authentication from './components/Authentication'
import Home from './pages/Home'

export default function App () {
  return (
    <div className="App mb-4">
      <ErrorBoundary>
        <Authentication>
          <Home />
        </Authentication>
      </ErrorBoundary>
    </div>
  )
}
