import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@prism/library'

export default function ErrorPage (props) {
  const { content } = props
  const { message = '', status = 'error', hasReloadBtn = false } = content || {}
  return (
    <div className="error-page text-center">
      {hasReloadBtn && (<div className="error-page__btn">
        <Button id="btn-reload-err" outline className="m-2" onClick={() => window.location.reload()}>
          <i className="icon btn-icon__left prism-icon-refresh" />
          Reload
        </Button>
      </div>)}
      <div className="error-page__status"><h3 className="mb-0">{status}</h3></div>
      <div className="error-page__message"><p dangerouslySetInnerHTML={{ __html: message }}></p></div>
    </div>
  )
}

ErrorPage.propTypes = {
  content: PropTypes.object.isRequired,
  message: PropTypes.string
}