import React from 'react'
import PropTypes from 'prop-types'
export default function CheckBoxComponent(props) {
    const { 
        id = "checkBoxGlobal", 
        name = "checkBoxGlobal", 
        className = "", 
        value = false, 
        checked = false, 
        disabled = false, 
        onChange = () => { }, 
        style = {}, 
        dataTestId = "checkBoxId" 
    } = props
    return (
        <div className="checkbox--custom">
            <label className={`itemCol itemContent checkbox-label ${className}`}>
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    style={style}
                    data-testid={dataTestId}
                />
            </label>
        </div>
    )
}

CheckBoxComponent.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    checked: PropTypes.bool,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
}
