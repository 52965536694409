import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../styles/loading-animation.scss'

export default function LoadingScreen (props) {
  const { size, className, animating } = props
  const [loading, setLoading] = useState(animating)
  useEffect(() => {
    setLoading(animating)
  }, [animating])
  return (
    <>
      {loading && (
        <div className='loading-animation'>
          <div>
            <div className="spinner" style={{ width: `${size}px`, height: `${size}px` }}>
              <i style={{ fontSize: `${size}px` }} className={`icon prism-icon-spinner spinner-icon ${className}`} id='spinner'></i>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
LoadingScreen.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  animating: PropTypes.bool
}
LoadingScreen.defaultProps = {
  animating: false,
  color: '#003468',
  size: 100
}